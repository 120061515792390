<template>
  <base-section
    id="olap-arch"
  >
    <base-section-heading :title="this.$t('archTitle')" />

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="8"
        >
          <base-img
            :src="require('@/assets/olap-olap-arch.png')"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionOlapArch',

    data: () => ({
    }),
  }
</script>
